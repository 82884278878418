import { useContext } from 'react';
import { GlobalContext } from '../../components/data/GlobalContext';



function RedesContent(props) {
    const dbData = useContext(GlobalContext);
    return (
        <div>
            <h2 className="text-center text-capitalize">Follow us</h2>

            <div className='contentRedes'>
                {
                    dbData.dbSocialMedia.directorios ?
                        dbData.dbSocialMedia.directorios.map((items, index) => {
                            return (
                                <div key={index}>
                                    <a href={items.url} target='_blank' rel="noreferrer">
                                        <img src={items.image} alt={items.name} />
                                    </a>
                                </div>
                            )
                        }) : null
                }
                {
                    dbData.dbSocialMedia.redes ?
                        dbData.dbSocialMedia.redes.map((items, index) => {
                            return (
                                <div key={index}>
                                    <a href={items.url} target='_blank' rel="noreferrer">
                                        <img src={items.image} alt={items.name} />
                                    </a>
                                </div>
                            )
                        }) : null
                }
            </div>
        </div>
    );
}

export default RedesContent;