import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";

import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";
import AboutOne from "../components/about/AboutOne";
import AboutTwo from "../components/about/AboutTwo";
import CallToActionTwo from "../components/call-to-action/CallToActionTwo";


const About = () => {
    const dbData = useContext(GlobalContext);

    return (
        <Layout pageTitle="About">
            <PagesHeader title="About us" crumbTitle="About Us"  pageHeaderBg={dbData.stock[25]}/>
            <AboutOne />
            <CallToActionTwo title={dbData.dbSlogan[3].slogan} bgImages={dbData.stock[15]}/>
            <AboutTwo images={dbData.stock[15]} />
        </Layout>
    );
}
export default About;