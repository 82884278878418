import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";
import PagesHeader from "../components/PagesHeader";
import Layout from "../components/Layout";


function Reviews() {
    const dbData = useContext(GlobalContext);

    return (
        <Layout PageName="Reviews">
            <div className="md:max-w-full w-full">
            <PagesHeader title="Reviews" crumbTitle="Reviews" pageHeaderBg={dbData.stock[10]}/>
            </div>
            <div className="w-4/5 mx-auto py-[100px]">
                <h1 className="text-center pb-10">{dbData?.labels?.general?.titleReviews}</h1>
                <div className={`${dbData?.reviews?.links?.[0]}`}></div>
            </div>
            
            
        </Layout>
    );
}

export default Reviews;
